import { createReducer } from 'redux-starter-kit';
import { WeWashApiErrorTag } from '../../http/errors';
import {
    REMEMBER_USER_BY_ID,
    SEARCH_PARTNER_BY_ANY_ID,
    SEARCH_PARTNER_BY_ANY_ID_FAILURE,
    SEARCH_PARTNER_BY_ANY_ID_SUCCESS,
    SEARCH_PARTNER_CLEAR,
    SEARCH_PARTNER_MANAGER,
    SEARCH_PARTNER_MANAGER_FAILURE,
    SEARCH_PARTNER_MANAGER_SUCCESS,
    SEARCH_USER,
    SEARCH_USER_CLEAR,
    SEARCH_USER_FAILURE,
    SEARCH_USER_ID,
    SEARCH_USER_ID_FAILURE,
    SEARCH_USER_ID_SUCCESS,
    SEARCH_USER_SUCCESS,
} from './searchUserActions';
import { RESET_VIEWS } from '../genericActions';
import { Location } from '../location/locationReducer';
import { PaymentMethodStatus } from '../manage-user/manageUserReducer';

export enum HubState {
    UP = 'UP',
    DOWN = 'DOWN',
    CRITICAL = 'CRITICAL',
    INITIAL = 'INITIAL',
    WARNING = 'WARNING',
}

export enum UserType {
    APP = 'APP',
    PHONE = 'PHONE',
}

export enum UserAccountState {
    ACTIVATING = 'ACTIVATING',
    ACTIVE = 'ACTIVE',
    RESET_PW = 'RESET_PW',
    BLOCKED = 'BLOCKED',
    DELETE = 'DELETE',
}

export enum InvoiceDeliveryMethod {
    E_MAIL = 'E_MAIL',
    LETTER = 'LETTER',
    NONE = 'NONE',
}

export enum UserParallelReservationsState {
    NORMAL = 'NORMAL',
    RESTRICTED = 'RESTRICTED',
    UNLIMITED = 'UNLIMITED',
}

export enum UserTrustLevel {
    NORMAL = 'NORMAL',
    VERIFIED = 'VERIFIED',
    RISK = 'RISK',
    JAIL = 'JAIL',
}

export enum JailReason {
    RESERVATION = 'RESERVATION',
    LAUNDRY_ROOM_SELECTION_THREAT = 'LAUNDRY_ROOM_SELECTION_THREAT',
    LAUNDRY_ROOM_SEARCH_USER = 'LAUNDRY_ROOM_SEARCH_USER',
    LAUNDRY_ROOM_SEARCH_IP = 'LAUNDRY_ROOM_SEARCH_IP',
    LAUNDRY_ROOM_SEARCH_ATTACK = 'LAUNDRY_ROOM_SEARCH_ATTACK',
    CC_UI = 'CC_UI',
}

export interface UserExtendedInformation {
    user_id: number;
    customer_id: number;
    name: string;
    account_state: UserAccountState;
    email: string;
    payment_information: string;
    payment_method_status: PaymentMethodStatus;
    has_debt: boolean;
    location: Location;
    hub_state: HubState;
    failure_rate: number;
    user_type: UserType;
    invoice_delivery_method: InvoiceDeliveryMethod;
    allow_plain_sepa_mandate_creation: boolean;
    phone_number: string;
    b2b_user: boolean;
    parallel_reservations_state: UserParallelReservationsState;
    parallel_reservations_limit: number;
    user_trust_level: UserTrustLevel;
    jail_reason: JailReason | null;
}

export interface ManagedPartner {
    id: number;
    customer_id: number;
    external_id: string;
    company_name: string;
    name: string;
    phone: string;
    country_id: number;
    street_and_housenumber: string;
    postalcode: string;
    city: string;
}

export interface PartnerExtendedInformation {
    email: string;
    first_name: string;
    last_name: string;
    company_name: string;
    partners: ManagedPartner[];
}

export interface SearchUserStore {
    resultsByEmail: UserExtendedInformation[];
    resultsById: UserExtendedInformation[];
    memoryUsersBydId: { [userId: number]: UserExtendedInformation };
    resultForPartnerManagerByEmail: PartnerExtendedInformation | null;
    resultByAnyId: { partners: ManagedPartner[] } | null;
    loading: boolean;
    searchTerm: string;
    searchUserId: number;
    errors: WeWashApiErrorTag[];
}

const initialStore: SearchUserStore = {
    resultsByEmail: [],
    resultsById: [],
    memoryUsersBydId: {},
    resultForPartnerManagerByEmail: null,
    resultByAnyId: null,
    loading: false,
    searchTerm: '',
    searchUserId: 0,
    errors: [],
};

export const searchUserReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [SEARCH_USER]: (state, action) => {
        state.loading = true;
        state.errors = [];
        state.searchTerm = action.payload.searchTerm;
    },
    [SEARCH_USER_SUCCESS]: (state, action) => {
        state.resultsByEmail = action.payload.data;
        state.resultsById = [];
        state.loading = false;
        state.errors = [];
    },
    [SEARCH_USER_FAILURE]: (state, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [SEARCH_USER_CLEAR]: (state, action) => {
        state.resultsByEmail = [];
    },
    [SEARCH_PARTNER_CLEAR]: (state, action) => {
        state.resultByAnyId = null;
        state.resultForPartnerManagerByEmail = null;
    },
    [SEARCH_USER_ID]: (state, action) => {
        state.loading = true;
        state.errors = [];
        state.searchUserId = action.payload.searchUserId;
    },
    [SEARCH_USER_ID_SUCCESS]: (state, action) => {
        state.resultsById = action.payload.data;
        state.loading = false;
        state.errors = [];
    },
    [REMEMBER_USER_BY_ID]: (state, action) => {
        const users: UserExtendedInformation[] = action.payload.data;
        users.forEach((user) => (state.memoryUsersBydId[user.user_id] = user));
    },
    [SEARCH_USER_ID_FAILURE]: (state, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [SEARCH_PARTNER_MANAGER]: (state) => {
        state.loading = true;
        state.errors = [];
    },
    [SEARCH_PARTNER_MANAGER_SUCCESS]: (state, action) => {
        state.resultForPartnerManagerByEmail = action.payload.data;
        state.loading = false;
        state.errors = [];
    },
    [SEARCH_PARTNER_MANAGER_FAILURE]: (state, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
        state.resultForPartnerManagerByEmail = null;
    },
    [SEARCH_PARTNER_BY_ANY_ID]: (state) => {
        state.loading = true;
        state.errors = [];
    },
    [SEARCH_PARTNER_BY_ANY_ID_SUCCESS]: (state, action) => {
        state.resultByAnyId = action.payload.data;
        state.loading = false;
    },
    [SEARCH_PARTNER_BY_ANY_ID_FAILURE]: (state, action) => {
        state.loading = false;
        state.resultByAnyId = null;
    },
});
